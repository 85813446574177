<div class="porHeader wrapper por-ac-header" [ngStyle]="{ width: width, height: height }" [ngClass]="{ 'quick-link': appFacadeService.versionToggleService.isQLButNotConsumerQL() }">
    <div class="header-logo-wrapper">
        <div
            class="hamburger-header mobile-menu-show"
            *ngIf="
                isMobile &&
                appFacadeService.versionToggleService.isAdvanceVersion() &&
                appFacadeService.versionToggleService.isNotQLOrConsumerQL() &&
                (appFacadeService.isPaymentSectionEnabled() | async) === false
            ">
            <ng-container>
                <por-hamburger icon="view_headline" panelClass="mobile-nav-items" [closeOnPanel]="true" [logo]="logo" classes="menu-hamburger">
                    <ul>
                        <ng-container *ngFor="let item of hamburgerItems">
                            <li
                                class="theme-list-item nav-items"
                                [ngClass]="{
                                    active: (appFacadeService.getActiveTab() | async) === item?.page
                                }"
                                (click)="changePage(item.page)">
                                <a class="toggle-sectnav">
                                    <span>{{ item.label | translate }}</span>
                                </a>
                            </li>
                        </ng-container>
                    </ul>
                </por-hamburger>
            </ng-container>
        </div>

        <a
            href="javascript:void(0)"
            class="logo"
            (click)="setHomePageTab()"
            [ngClass]="{
                'logo-left': header?.logoPosition === 'left',
                'logo-right': header?.logoPosition === 'right',
                'logo-center': header?.logoPosition === 'center',
                'title-right': header?.headerTitlePosition === 'right',
                'title-left': header?.headerTitlePosition === 'left',
                'title-center': header?.headerTitlePosition === 'center',
                'depot-wrapper': logo === '',
                'payment-enabled': (appFacadeService.isPaymentSectionEnabled() | async)
            }"
            porAddFeature
            featureName="logo">
            <ng-container *ngIf="showHeaderSection()">
                <img [ngStyle]="{ width: logoWidth }" class="header-logo" [src]="logo" *ngIf="!header?.depotName; else showDepotName" />
                <ng-template #showDepotName>
                    <span class="depot-name">{{ header?.depotName }}</span>
                </ng-template>
            </ng-container>
            <span *ngIf="title">
                {{ title }}
            </span>
        </a>
    </div>

    <por-page-sidebar [config]="config" *ngIf="appFacadeService.versionToggleService.isAdvanceVersion() && appFacadeService.versionToggleService.isNotQLOrConsumerQL()"></por-page-sidebar>
    <div class="profile-hamburger" *ngIf="showHeaderSection() && (!isMobile || (appFacadeService.isPaymentSectionEnabled() | async) === false)">
        <por-hamburger
            icon="person"
            *ngIf="appFacadeService.versionToggleService.isNotQLOrConsumerQL()"
            btnimg="{{ uiUrl }}/assets/images/CP_Profile icon_ final.svg"
            classes="person-hamburger"
            porAddFeature
            featureName="profile-button">
            <ul>
                <span class="username">{{ customer?.Name }}</span>
                <li (click)="logout.emit()" *ngIf="appFacadeService.featureToggleService.isAvailable('logOff')">
                    <a porAddFeature featureName="logout-button">
                        <mat-icon>logout</mat-icon>
                        <span>{{ 'Logout' | translate }}</span>
                    </a>
                </li>
                <li class="lang-select-wrapper">
                    <por-language-selector (selectedLocale)="handleSelectedLocale($event)" [options]="languageOptions" [selectedLanguageOption]="selectedLanguageOption"></por-language-selector>
                </li>
            </ul>
        </por-hamburger>
        <por-hamburger
            *ngIf="showLoginMoreInfoButton()"
            icon="view_headline"
            panelClass="mobile-nav-items"
            icon="person"
            btnimg="{{ uiUrl }}/assets/images/CP_Profile icon_ final.svg"
            classes="person-hamburger"
            porAddFeature
            featureName="profile-button"
            (click)="openModal()">
            <div class="menu-inner" *ngIf="visible">
                <span class="cross-icon" (click)="onClose()">X</span>
                <h2 *ngIf="this.appFacadeService.featureToggleService.isAvailable('multiplePaymentAbility'); else quickInfoTitle">{{ 'QuickInfoForMultiplePayment' | translate }}</h2>
                <ng-template #quickInfoTitle>
                    <h2>{{ 'QuickInfo' | translate }}</h2>
                </ng-template>

                <por-base-button featureName="login-page" [theme]="'primary'" (click)="openConsumerPortal()">
                    {{ 'LoginMoreContracts' | translate }}
                </por-base-button>
                <div class="list">
                    <div class="list-title">{{ 'ThisNeeded' | translate }}:</div>
                    <ul>
                        <li>{{ 'SingleContract' | translate }}</li>
                        <li *ngIf="!this.appFacadeService.featureToggleService.isAvailable('secureUrlLoginRequired')">{{ 'ViewPDF' | translate }}</li>
                        <li *ngIf="!this.appFacadeService.featureToggleService.isAvailable('secureUrlLoginRequired')">{{ 'Esigndoc' | translate }}</li>
                    </ul>
                </div>
            </div>
        </por-hamburger>
    </div>
</div>
