import { Action } from '@ngrx/store';
import { ComponentsToLoad, SubComponentsToLoad } from '../../enums/components-to-load.enum';
import { ConsumerPortalState } from '../../types/state.type';
import { Redirection } from '../../types/redirection.type';

/**
 * ToDo: We already have appState, checkout why is it not being used anymore?
 * Story: 79984 to organize it, so isLoading, isAuthenticated etc. can move
 * from domain state to appState
 */
export interface AppState {
    isLoading: boolean;
    isAuthenticated: boolean;
    currencyCode: {
        customerCurrencyCode: string | null;
        contractsCurrencyCode: string | null;
    };
    activeTab: {
        parentTab: ComponentsToLoad;
        childTab: SubComponentsToLoad;
    };
    isAdminLoading: boolean;
    consumerPortalState: ConsumerPortalState;
    isRedirected: boolean;
    versionRedirection: Redirection | null;
    isMobile: boolean;
}

export const initialState: AppState = {
    isLoading: false,
    isAuthenticated: false,
    currencyCode: {
        customerCurrencyCode: null,
        contractsCurrencyCode: null
    },
    activeTab: {
        parentTab: ComponentsToLoad.AccountSummary,
        childTab: SubComponentsToLoad.Default
    },
    isAdminLoading: false,
    versionRedirection: null,
    consumerPortalState: 'None',
    isRedirected: false,
    isMobile: false
};

export type ReducerType = (state: AppState | undefined, action: Action) => AppState;
