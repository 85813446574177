import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
    selector: 'por-overlay',
    templateUrl: './overlay.component.html',
    styleUrls: ['./overlay.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverlayComponent {
    @Input() isOpen = false; // Overlay visibility state
    @Input() trigger!: CdkOverlayOrigin; // Pass the trigger from parent
    @Input() featureName!: string;

    @Output() readonly closeOverlayClick = new EventEmitter<void>(); // Event to emit when closing
    constructor(private readonly elementRef: ElementRef) {}

    closeOverlay() {
        this.closeOverlayClick.emit(); // Emit close event to parent
    }

    @HostListener('document:click', ['$event.target'])
    public onPageClick(targetElement: HTMLElement) {
        if (this.isOpen && !this.elementRef.nativeElement.contains(targetElement) && !this.trigger.elementRef.nativeElement.contains(targetElement)) {
            this.closeOverlay();
        }
    }

    /**
     * Prevent closing the overlay when clicking inside it.
     */
    onOverlayClick(event: MouseEvent) {
        event.stopPropagation();
    }
}
