import { activeTabReducer, paymentComponentReducer } from './reducers/active-tab.reducer';
import { authReducer } from './reducers/auth.reducer';
import { configReducer } from './reducers/config.reducer';
import { contractReducer } from './reducers/contracts.reducer';
import { currencyReducer } from './reducers/currency-code.reducer';
import { customerSummaryReducer } from './reducers/customer-reducer';
import { documentReducer } from './reducers/documents.reducer';
import { eventLogsReducer } from './reducers/event-logs.reducer';
import { invoicesReducer } from './reducers/invoices.reducer';
import { itemsOutReducer } from './reducers/items-out.reducer';
import { loadedReducer } from './reducers/loaded.reducers';
import { loaderReducer } from './reducers/loader.reducer';
import { paymentProcessorsReducer } from './reducers/processors.reducer';
import { quickLinkDataReducer } from './reducers/quicklink.reducer';
import { redirectionReducer } from './reducers/redirection.reducer';
import { loadstateReducer } from './reducers/view-state.reducers';
import { redirectionVersionReducer } from './reducers/version.reducer';

type Store = {
    customer: typeof customerSummaryReducer;
    contracts: typeof contractReducer;
    itemsOut: typeof itemsOutReducer;
    invoices: typeof invoicesReducer;
    config: typeof configReducer;
    isLoading: typeof loaderReducer;
    isAuthenticated: typeof authReducer;
    docData: typeof documentReducer;
    eventLogs: typeof eventLogsReducer;
    activeTab: typeof activeTabReducer;
    quickLinkData: typeof quickLinkDataReducer;
    redirection: typeof redirectionReducer;
    currency: typeof currencyReducer;
    paymentSectionEnabled: typeof paymentComponentReducer;
    isAdminLoading: typeof loadedReducer;
    isPaymentProcessorAvailable: typeof paymentProcessorsReducer;
    consumerPortalState: typeof loadstateReducer;
    redirectionVersion: typeof redirectionVersionReducer;
};

const store: Store = {
    customer: customerSummaryReducer,
    contracts: contractReducer,
    itemsOut: itemsOutReducer,
    invoices: invoicesReducer,
    config: configReducer,
    isLoading: loaderReducer,
    isAuthenticated: authReducer,
    docData: documentReducer,
    eventLogs: eventLogsReducer,
    activeTab: activeTabReducer,
    quickLinkData: quickLinkDataReducer,
    redirection: redirectionReducer,
    currency: currencyReducer,
    paymentSectionEnabled: paymentComponentReducer,
    isAdminLoading: loadedReducer,
    isPaymentProcessorAvailable: paymentProcessorsReducer,
    consumerPortalState: loadstateReducer,
    redirectionVersion: redirectionVersionReducer
};
export default store;
