import { ContractCP } from '../../models/contract-model';
import { ContractState, contractsFeatureKey } from './../reducers/contracts.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const contractSelector = createFeatureSelector<ContractState>(contractsFeatureKey);

export const getPageNumber = createSelector(contractSelector, (state: ContractState) => state.pageNumber);

export const getContracts = createSelector(contractSelector, (state: ContractState) => state.contracts);

export const getLoading = createSelector(contractSelector, (state: ContractState) => state.loading);

export const getNoMoreRecord = createSelector(contractSelector, (state: ContractState) => state.noMoreRecord);

export const getSelected = createSelector(contractSelector, (state: ContractState) => state.selectedContractId);

export const getSelectedContracts = createSelector(contractSelector, (state: ContractState) => state.selectedContracts);

export const getCreditContracts = createSelector(contractSelector, (state: ContractState) => {
    return state.contracts.filter((contract: ContractCP) => state.selectedContracts.includes(contract.contractId) && contract.contractId.startsWith('c'));
});

export const getContractsTotal = createSelector(contractSelector, (state: ContractState) => {
    let total = 0;

    state.contracts
        .filter((contract: ContractCP) => state.selectedContracts.includes(contract.contractId))
        .forEach((contract: ContractCP) => {
            if (contract.contractId.startsWith('c')) {
                total -= Math.abs(Number(contract.AmountDue));
            } else {
                total += Number(contract.AmountDue);
            }
        });

    return total;
});
