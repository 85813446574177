<div class="portalnav-wrap">
    <div class="portalnav">
        <div class="arrow-back">
            <por-base-button theme="primary" (onclick)="return()" class="p-0" [featureName]="'close-clone-contract-form-button'">
                <mat-icon>close</mat-icon>
            </por-base-button>
        </div>
        <h1 class="align-items-center d-inline-flex">
            <img class="header-image" src="{{ uiUrl }}/assets/images/copy.svg" width="40" />
            {{ 'RequestToBookAgainForm' | translate }}
        </h1>
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <th>{{ 'itemName' | translate }}</th>
                    <th>{{ 'ItemId' | translate }}</th>
                    <th>{{ 'Quantity' | translate }}</th>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of contractDetail?.[0]?.LineItems; let i = index">
                        <tr *ngIf="i < displayedRowCount">
                            <td class="text-center">{{ item?.Name }}</td>
                            <td class="text-center">{{ item?.ProductId ?? item?.StockId }}</td>
                            <td class="text-center">{{ item?.Quantity }}</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div *ngIf="showLoadMoreButton()" class="text-align-center mt-2">
            <por-base-button (click)="displayedRowCount = displayedRowCount + 10" featureName="clone-contract-form-loadmore-button">{{ 'Load More' | translate }}</por-base-button>
        </div>
        <form class="form-wrapper mt-2" [formGroup]="cloneContractForm" porAddFeature featureName="clone-contract-form">
            <por-accordion [defaultOpenedIndex]="0">
                <por-accordion-group title="{{ 'NewContractDateRequest' | translate }}">
                    <div class="form-group mt-2">
                        <div class="col-2">
                            <label>
                                {{ 'RequestedNewContractStartDate' | translate }}
                                <span class="star-err">*</span>
                            </label>
                            <mat-form-field appearance="outline">
                                <input
                                    matInput
                                    [matDatepicker]="picker"
                                    (focus)="picker.open()"
                                    formControlName="startDate"
                                    [min]="minDate"
                                    required
                                    porAddFeature
                                    featureName="clone-contract-startdate-input" />
                                <mat-datepicker-toggle matSuffix [for]="picker" porAddFeature featureName="picker-clone-contract-startdate-datepicker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <div
                                class="error flex mt-xs px-xs ng-star-inserted"
                                *ngIf="startDate?.invalid &&
								(startDate?.dirty || startDate?.touched) &&
								(startDate?.errors?.['required'] || startDate?.errors?.['futureDate'])">
                                <div class="flex items-center justify-center text-error ng-star-inserted">
                                    <i class="flex items-center mr-xs w-xs max-h-xs">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="fill-current" style="width: 16px; height: 16px">
                                            <use href="assets/symbols.svg#info"></use>
                                        </svg>
                                    </i>
                                    <p class="p-none m-none">{{ 'InvalidDate' | translate }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-2">
                            <label>
                                {{ 'Time' | translate }}
                                <span class="star-err">*</span>
                            </label>
                            <mat-form-field appearance="outline">
                                <input
                                    matInput
                                    matTimepicker
                                    [ngxMatTimepicker]="timepicker"
                                    class="mat-time-picker-custom"
                                    [format]="24"
                                    [required]="true"
                                    placeholder="{{ 'Choose a time' | translate }}"
                                    formControlName="startTime"
                                    [required]="true"
                                    porAddFeature
                                    featureName="calloff-time-input"
                                    (click)="openTimePicker()"
                                    [value]="currentTime" />
                                <ngx-mat-timepicker #timepicker></ngx-mat-timepicker>
                                <mat-icon matSuffix (click)="openTimePicker()">access_time</mat-icon>
                            </mat-form-field>
                            <div
                                class="error flex mt-xs px-xs ng-star-inserted"
                                *ngIf="startTime?.invalid &&
								(startTime?.dirty || startTime?.touched) &&
								startTime?.errors?.['required'] ">
                                <div class="flex items-center justify-center text-error ng-star-inserted">
                                    <i class="flex items-center mr-xs w-xs max-h-xs">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="fill-current" style="width: 16px; height: 16px">
                                            <use href="assets/symbols.svg#info"></use>
                                        </svg>
                                    </i>
                                    <p class="p-none m-none">{{ 'RequiredError' | translate }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </por-accordion-group>
                <por-accordion-group title="{{ 'Modifications' | translate }}" [isValid]="isValidContractDateTime()">
                    <div class="form-group font-roboto font-medium text-input text-text-base items-start">
                        <div class="col-1">
                            <label class="mb-xs">
                                <span>{{ 'ModificationsDetailsText' | translate }}</span>
                                <span class="sub-text">{{ 'ModificationsDetailsSubText' | translate }}</span>
                            </label>
                            <textarea
                                class="font-roboto font-medium rounded-input border-solid border-input px-xs text-input text-input-text border-input-border-default"
                                formControlName="modifications"
                                porAddFeature
                                featureName="clone-contract-details-textarea"></textarea>
                        </div>
                    </div>
                </por-accordion-group>
                <por-accordion-group title="{{ 'RequesterInformation' | translate }}">
                    <div class="form-group mt-2">
                        <div class="col-2">
                            <apx-form-field
                                label="{{ 'NameOfPersonSubmittingRequest' | translate }}"
                                [classList]="'required'"
                                [error]="customerName?.invalid && (customerName?.dirty || customerName?.touched) ?
							customerName?.errors?.['required'] ? ('RequiredError' | translate) :
                            customerName?.errors?.['maxlength'] ? ('OverLimitChars' | translate) :''
							: '' ">
                                <input
                                    apxInput
                                    placeholder="{{ 'Enter the name of Person' | translate }}"
                                    formControlName="customerName"
                                    [ngModel]="(customer$ | async)?.Name"
                                    porAddFeature
                                    featureName="clone-contract-name-input" />
                            </apx-form-field>
                        </div>
                        <div class="col-2">
                            <apx-form-field
                                label="{{ 'Phone Number' | translate }}"
                                [classList]="'required'"
                                [error]="customerPhone?.invalid && (customerPhone?.dirty || customerPhone?.touched) ?
							customerPhone?.errors?.['required'] ? ('RequiredError' | translate) :
                            customerPhone?.errors?.['maxlength'] ? ('OverLimitChars' | translate) :''
							: '' ">
                                <input
                                    apxInput
                                    placeholder="{{ 'Phone Number' | translate }}"
                                    formControlName="customerPhone"
                                    [ngModel]="(customer$ | async)?.Phones ? ((customer$ | async)?.Phones | formatNumberCustomer) : ''"
                                    porAddFeature
                                    featureName="clone-contract-phone-input" />
                            </apx-form-field>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-1">
                            <apx-form-field
                                label="{{ 'Email Address' | translate }}"
                                [classList]="'required'"
                                [error]="email?.invalid && (email?.dirty || email?.touched) ?
							email?.errors?.['required'] ? ('RequiredError' | translate) :
							email?.errors?.['email'] ? ('EmailValid' | translate) :
                            email?.errors?.['maxlength'] ? ('OverLimitChars' | translate) : ''
							: '' ">
                                <input
                                    apxInput
                                    type="email"
                                    placeholder="{{ 'Enter email address' | translate }}"
                                    formControlName="email"
                                    [ngModel]="customerEmail"
                                    porAddFeature
                                    featureName="clone-contract-email-input" />
                            </apx-form-field>
                        </div>
                    </div>
                </por-accordion-group>
            </por-accordion>
            <div class="form-group mt-2 justify-content-center">
                <por-base-button
                    [featureName]="'SubmitRequestToBook' | translate"
                    [type]="'submit'"
                    [disabled]="cloneContractForm.invalid.toString() | convertBoolean"
                    (onclick)="sendCloneContractForm()">
                    {{ 'SubmitRequestToBook' | translate }}
                </por-base-button>
            </div>
        </form>
    </div>
</div>
