import { Observable, catchError, map, of, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LoggerService } from '../../services/logger.service';
import { ConsumerPortalApiService } from '../../services/consumer-portal-api.service';
import { PaymentData, PaymentMinMaxBoundary, PaymentResponse } from '../models/payment-data.model';
import { Processors } from '../models/processors.interface';
import { CPEnvironment } from '../../models';
import { ConsumerPortalEnviromentConfig } from '../../models/environment-consumer-portal';
import { Payment } from '../../models/contract-model';
@Injectable({
    providedIn: 'root'
})
export class PorPayService {
    constructor(
        private readonly logger: LoggerService,
        private readonly consumerPortalApi: ConsumerPortalApiService,
        @Inject(CPEnvironment) private readonly appConfig: ConsumerPortalEnviromentConfig
    ) {}

    loadScriptForPOR() {
        this.logger.logWarning('Will Load Script Here');
    }

    createPayment(
        /* eslint-disable @typescript-eslint/naming-convention */
        /**
         * Note camelCase : DB Model
         */
        CustomerGuid: string,
        CurrencyCode: string,
        Amount_Requested: number,
        OrganizationId: string,
        RemoteDepotId: string,
        contractId: string,
        amountBeingPaidBeforeFee: number
    ): Observable<PaymentResponse> {
        return this.consumerPortalApi
            .post<PaymentResponse>({
                controller: `payment/${CustomerGuid}/create-transaction`,
                method: 'POST',
                body: {
                    CustomerGuid: String(CustomerGuid),
                    Amount_Requested: Number(Amount_Requested),
                    CurrencyCode: String(CurrencyCode),
                    RemoteDepotId: String(RemoteDepotId),
                    contractId,
                    amountBeingPaidBeforeFee
                },
                headers: {
                    'x-organization-id': OrganizationId
                }
            })
            .pipe(
                tap({
                    error: (err: HttpErrorResponse) => {
                        this.logger.logWarning(err?.message);
                        this.logger.alertDevError(err);
                        return err;
                    },
                    next: (response: PaymentResponse) => {
                        this.logger.logInfo(response);
                        return response?.data;
                    }
                })
            );
    }

    verifyPayment(customerId: string, paymentData: PaymentData): Observable<Payment> {
        return this.consumerPortalApi
            .post<Payment>({
                controller: `payment/${customerId}/verify-transaction`,
                method: 'POST',
                body: paymentData,
                headers: {
                    'x-organization-id': paymentData.organizationId ?? ''
                },
                camelCase: false
            })
            .pipe(
                tap({
                    error: (err: HttpErrorResponse) => {
                        return err;
                    },
                    next: (response: Payment) => {
                        return response;
                    }
                })
            );
    }

    getMinMaxFromPaymentBoundary(customerId: string, contractId: string): Observable<PaymentMinMaxBoundary[]> {
        return this.consumerPortalApi.get<PaymentMinMaxBoundary>(`payment/${customerId}/get-boundary/${contractId}`).pipe(
            tap({
                next: (data: PaymentMinMaxBoundary[]) => {
                    return data;
                },
                error: (error: HttpErrorResponse) => {
                    return error;
                }
            })
        );
    }

    /**
     * Get the payment processors
     * @param {string} customerId
     * @returns Processors[]
     */
    getProcessors(customerId: string): Observable<Processors[]> {
        return this.consumerPortalApi.get<Processors>(`payment/${customerId}/processors`).pipe(
            tap({
                next: (data: Processors[]) => {
                    return data;
                },
                error: (error: HttpErrorResponse) => {
                    return error;
                }
            })
        );
    }

    /**
     * Hide Make Payment button based on payment processors, no processors are setup, hide and if consumer facing
     * is not available then also hide
     * @param {string} customerId
     * @returns {Observable<boolean | Processors[]>} false or Processors[]
     */
    isPaymentProcessorAvailable(customerId: string): Observable<boolean | Processors[]> {
        return this.getProcessors(customerId).pipe(
            map((processors: Processors[]) => {
                if (processors.length === 0) {
                    return false;
                }
                let availableProcessors: Processors[] = processors.filter(processor => processor.RemoteDepotId !== null);
                if (this.appConfig?.envName !== 'stage') {
                    availableProcessors = availableProcessors.filter(processor => processor?.IsCustomerFacing);
                }

                if (availableProcessors.length === 0) {
                    return false;
                }

                return availableProcessors;
            }),
            catchError((error: HttpErrorResponse) => {
                this.logger.logError(error, '', true);
                return of(false);
            })
        );
    }
}
