import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridApi, ICellRendererParams } from 'ag-grid-community';
import { DateUtility } from '../../date.utility';
import { DateFormatType } from '../../enums/date-format-type.enum';
import { ContractCP } from '../../models/contract-model';
import { RowLabelValue } from '../../models/row-label-value-model';
import { FeatureToggleService } from '../../services/feature-toggle.service';
import { FormatCurrencyPipe } from '../../pipes/format-currency.pipe';
import { AppFacadeService } from '../../services/app-facade.service';
import { Observable, Subscription } from 'rxjs';

@Component({
    selector: 'por-contract-full-width-row',
    templateUrl: './contract-full-width-row.component.html',
    styleUrls: ['./contract-full-width-row.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractFullWidthRowComponent implements ICellRendererAngularComp, OnInit, OnDestroy {
    public params!: ICellRendererParams;
    public isAllSelected = false;
    public contract!: ContractCP;
    public contractKV: RowLabelValue[] = [];
    public dateFormat = '';
    private gridApi!: GridApi;
    subscriptions: Subscription[] = [];
    selectedContracts$!: Observable<string[]>;
    selectedContracts: string[] = [];
    subscription: Subscription = new Subscription();
    constructor(
        @Inject(LOCALE_ID) locale: string,
        private readonly date: DatePipe,
        private readonly featureService: FeatureToggleService,
        private readonly formatCurrency: FormatCurrencyPipe,
        private readonly appFacadeService: AppFacadeService
    ) {
        this.dateFormat = DateUtility.getDateDisplayFormat(DateFormatType.StandardDate, locale);
    }
    ngOnInit(): void {
        this.subscriptions.push(
            this.appFacadeService.getSelectedContracts().subscribe(contracts => {
                this.selectedContracts = [...contracts];
            })
        );
    }

    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.contract = { ...params.data };
        this.gridApi = params.api;

        this.contractKV = [
            {
                label: 'contractId',
                value: this.contract?.contractId
            },
            {
                label: 'amount',
                value: this.formatCurrency.transform(String(this.contract.GrandTotal))
            },
            {
                label: 'amountDue',
                value: this.formatCurrency.transform(String(this.contract.AmountDue))
            },
            {
                label: 'status',
                value: this.contract?.status
            },
            {
                label: 'startDate',
                value: this.date.transform(this.contract?.openDate, this.dateFormat)
            },
            {
                label: 'closeDate',
                value: this.date.transform(this.contract?.closeDate, this.dateFormat)
            },
            {
                label: 'purchaseOrder',
                value: this.contract?.purchaseOrder
            },
            {
                label: 'location',
                value: this.contract?.info
            }
        ];

        if (this.featureService.isAvailable('contractGridJobColumnAvailable')) {
            this.contractKV.push({
                label: 'jobNumber',
                value: this.contract?.jobNumber
            });
        }
    }

    refresh() {
        return true;
    }

    loadContractDetail() {
        this.params.context?.componentParent.loadContractDetailModal(this.contract?.contractId);
    }

    onCheckboxChange(isChecked: boolean): void {
        if (isChecked) {
            this.selectedContracts.push(this.contract.contractId);
        } else {
            this.selectedContracts.splice(this.selectedContracts.indexOf(this.contract.contractId), 1);
        }
        this.appFacadeService.setSelectedContracts(this.selectedContracts);
    }
    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
