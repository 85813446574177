import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '../material-modules';
import { BaseButtonToggleComponent } from './components/base-button-toggle/base-button-toggle.component';
import { BaseButtonComponent } from './components/base-button/base-button.component';
import { BaseInputComponent } from './components/base-input/base-input.component';
import { BaseRadioComponent } from './components/base-radio/base-radio.component';
import { BaseSelectComponent } from './components/base-select/base-select.component';
import { BaseTextareaComponent } from './components/base-textarea/base-textarea.component';
import { BaseToggleComponent } from './components/base-toggle/base-toggle.component';
import { GoogleMapComponent } from './components/google-map/google-map.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ModalComponent } from './components/modal/modal.component';
import { CountPipe } from './pipes/count.pipe';
import { FormatAddressPipe } from './pipes/format-address.pipe';
import { FormatNumberCustomerPipe } from './pipes/format-number-customer.pipe';
import { FiltersComponent } from './components/filters/filters.component';
import { ApxButtonModule, ApxCheckboxModule, ApxFormsModule, ApxIconModule, ApxInputModule, ApxRadioModule } from '@por/apex';
import { AccordionGroupComponent } from './components/accordion/accordion-group.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { UIMissingTranslationHandler, UITranslateLoader } from '@por/shared/ui/translations';
import { Language } from '@por/shared/core';
import * as EnUSTranslations from '../../assets/en-US.json';
import * as EnGBTranslations from '../../assets/en-GB.json';
import * as FrCATranslations from '../../assets/fr-CA.json';

import { ConsumerLoginLanguage } from '@por/consumer-login/ui-lib';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddDotsToTextPipe } from './pipes/add-dots-to-text.pipe';
import { SearchBoxComponent } from './components/search-box/search-box.component';
import { DateFilterComponent } from './components/date-filter/date-filter.component';
import { MatBadgeModule } from '@angular/material/badge';
import { ClickOutSideDirective } from './directive/click-out-side.directive';
import { ConvertBooleanPipe } from './pipes/convert-boolean.pipe';
import { PushStateDirective } from './directive/push-state-directive.directive';
import { AddFeatureClassDirective } from './directive/add-feature-class.directive';
import { DragNDropComponent } from './components/drag-n-drop/drag-n-drop.component';
import { PorDndDirective } from './components/drag-n-drop/directive/ndndirectives';
import { ProgressComponent } from './components/drag-n-drop/sub-components/progress/progress.component';
import { DEFAULT_LOCALE } from '../constants/default.const';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { FormatCurrencyPipe } from '../pipes/format-currency.pipe';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { IntlTelInputComponent } from './components/intl-tel-input/intl-tel-input.component';
import { ErrorDailogBoxComponent } from './components/error-dailog-box/error-dailog-box.component';
import { BaseMatBadgeComponent } from './components/base-mat-badge/base-mat-badge.component';
import { OverlayComponent } from './components/overlay/overlay.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { BaseCheckboxComponent } from './components/base-checkbox/base-checkbox.component';
@NgModule({
    declarations: [
        GoogleMapComponent,
        BaseButtonComponent,
        BaseButtonToggleComponent,
        BaseInputComponent,
        BaseRadioComponent,
        BaseSelectComponent,
        BaseTextareaComponent,
        BaseToggleComponent,
        LoaderComponent,
        ModalComponent,
        CountPipe,
        FormatAddressPipe,
        FormatNumberCustomerPipe,
        FiltersComponent,
        AccordionGroupComponent,
        AccordionComponent,
        AddDotsToTextPipe,
        SearchBoxComponent,
        DateFilterComponent,
        ClickOutSideDirective,
        ConvertBooleanPipe,
        PushStateDirective,
        AddFeatureClassDirective,
        DragNDropComponent,
        PorDndDirective,
        ProgressComponent,
        ConfirmDialogComponent,
        FormatCurrencyPipe,
        IntlTelInputComponent,
        ErrorDailogBoxComponent,
        BaseMatBadgeComponent,
        OverlayComponent,
        BaseCheckboxComponent
    ],
    imports: [
        ReactiveFormsModule,
        FormsModule,
        ApxButtonModule,
        ApxRadioModule,
        CommonModule,
        MaterialModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: () => {
                    return new UITranslateLoader({
                        [Language.EN_US]: {
                            ...EnUSTranslations,
                            ...ConsumerLoginLanguage.enUSTranslations
                        },
                        [Language.EN_GB]: {
                            ...EnGBTranslations,
                            ...ConsumerLoginLanguage.enGBTranslations
                        },
                        [Language.FR_CA]: {
                            ...FrCATranslations
                        }
                    });
                }
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: UIMissingTranslationHandler
            },
            defaultLanguage: DEFAULT_LOCALE
        }),
        ApxInputModule,
        MatBadgeModule,
        ApxCheckboxModule,
        ApxFormsModule,
        ApxIconModule,
        NgxIntlTelInputModule,
        OverlayModule
    ],
    exports: [
        GoogleMapComponent,
        BaseButtonComponent,
        BaseButtonToggleComponent,
        BaseInputComponent,
        BaseRadioComponent,
        BaseSelectComponent,
        BaseTextareaComponent,
        BaseToggleComponent,
        LoaderComponent,
        ModalComponent,
        CountPipe,
        FormatAddressPipe,
        FormatNumberCustomerPipe,
        FiltersComponent,
        AccordionGroupComponent,
        AccordionComponent,
        AddDotsToTextPipe,
        ClickOutSideDirective,
        ConvertBooleanPipe,
        PushStateDirective,
        AddFeatureClassDirective,
        DragNDropComponent,
        SearchBoxComponent,
        ConfirmDialogComponent,
        FormatCurrencyPipe,
        IntlTelInputComponent,
        ErrorDailogBoxComponent,
        BaseMatBadgeComponent,
        OverlayComponent,
        BaseCheckboxComponent
    ]
})
export class SharedModule {}
