import { loadContractAction, setLoading, setNoMoreRecord, clearContractinStore, increasePageNumberContract, openContract, setSelectedContracts } from '../actions/contract.actions';
import { createReducer, on } from '@ngrx/store';
import { ContractCP } from '../../models/contract-model';

export const contractsFeatureKey = 'contracts';

export interface ContractState {
    pageNumber: number;
    contracts: ContractCP[];
    selectedContracts: string[];
    loading: boolean;
    noMoreRecord: boolean;
    selectedContractId: string;
}

export const initialState: ContractState = {
    pageNumber: 1,
    contracts: [],
    selectedContracts: [],
    loading: false,
    noMoreRecord: false,
    selectedContractId: ''
};

export const contractReducer = createReducer(
    initialState,
    on(loadContractAction, (state: ContractState, { contracts }: { contracts: ContractCP[] }) => {
        if (contracts && contracts.length > 0) {
            return {
                ...state,
                contracts: [...state.contracts, ...contracts]
            };
        }
        return state;
    }),
    on(setLoading, (state: ContractState, { loading }: { loading: boolean }) => {
        return { ...state, loading };
    }),
    on(setNoMoreRecord, (state: ContractState, { record }: { record: boolean }) => {
        return { ...state, noMoreRecord: record };
    }),
    on(clearContractinStore, (state: ContractState) => {
        return { ...state, contracts: [], pageNumber: 1 };
    }),
    on(increasePageNumberContract, (state: ContractState) => {
        return { ...state, pageNumber: state?.pageNumber + 1 };
    }),
    on(openContract, (state: ContractState, { selectedContractId }: { selectedContractId: string }) => {
        return { ...state, selectedContractId };
    }),
    on(setSelectedContracts, (state: ContractState, { selectedContracts }: { selectedContracts: string[] }) => {
        return { ...state, selectedContracts };
    })
);
