import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription, catchError, filter, finalize, map, of, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs';
import isEmpty from 'lodash-es/isEmpty';
import { AppFacadeService } from '../services/app-facade.service';
import { ConsumerPortalConfig } from '../models/consumer-portal-config';
import { Customer } from '../models/consumer';
import { Store, select } from '@ngrx/store';
import { getCustomer } from '../store/selector/customer.selectors';
import { ContractService } from '../services/contract.service';
import { TranslateService } from '@ngx-translate/core';
import { QuickLink } from '../models/quicklink.model';
import { LoggerService } from '../services/logger.service';
import { AppMediatorService } from '../services/app-mediator.service';
import { selectQuickLinkData } from '../store/selector/quicklink.selectors';

@Component({
    selector: 'por-quick-link',
    templateUrl: './quick-link.component.html',
    styleUrls: ['./quick-link.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuickLinkComponent implements OnInit, OnDestroy {
    @Input() config!: string;
    @Output() readonly logout = new EventEmitter();
    @Output() readonly returnToLoginFromQuickLink = new EventEmitter<string>();
    @Output() readonly returnQuickLinkFromLogin = new EventEmitter<string>();
    inputs: ConsumerPortalConfig | undefined;
    subscriptions: Subscription[] = [];
    customerId? = '';
    contractId = '';
    quickLinkUrl = '';
    isLoginButtonVisible = true;
    /**
     * This has been used to complete observable stream when the onDestroy$ subject
     * emits a value (i.e., when the component is destroyed).
     * This will automatically unsubscribe from the observable,
     * preventing any further emissions and ensuring that resources are properly released.
     */
    private readonly onDestroy$: Subject<void> = new Subject<void>();
    customer$ = this.store.pipe(
        select(getCustomer),
        map((customer: Customer) => {
            this.appFacadeService.setLoading(true);
            if (isEmpty(customer) && this.customerId) {
                this.appFacadeService.fetchCustomerById(this.customerId);
            }

            return customer;
        }),
        takeUntil(this.onDestroy$),
        finalize(() => {
            this.appFacadeService.setLoading(false);
        })
    );
    quickLinkData$ = this.store.pipe(select(selectQuickLinkData));

    isDetailPageOpened = false;
    invalidRequest = false;
    contractDetail$ = of([]).pipe(
        map(x => {
            this.appFacadeService.setLoading(true);
            if (!this.customerId || !this.contractId) {
                this.invalidRequest = true;
                return [];
            }

            return x;
        }),
        switchMap(() => {
            return this.contractService.getContractsDetails(this.customerId as string, this.contractId as string).pipe(
                switchMap(data => {
                    this.isDetailPageOpened = true;
                    // Add contract to store for payment page. depotId is importat for payment.
                    this.appFacadeService.setContracts([this.contractService.convertContractDetailToContractCP(data[0])]);
                    return of(data);
                })
            );
        }),
        catchError(() => {
            this.invalidRequest = true;
            return of([]);
        }),
        finalize(() => {
            this.appFacadeService.setLoading(false);
        })
    );

    constructor(
        private readonly store: Store,
        readonly appFacadeService: AppFacadeService,
        private readonly contractService: ContractService,
        private readonly translateService: TranslateService,
        private readonly logger: LoggerService,
        private readonly appMediatorService: AppMediatorService
    ) {}

    ngOnInit(): void {
        this.quickLinkUrl = window.location.href;
        this.translateService.use(this.appMediatorService.localStorageService.selectedContentLanguage);

        if (this.config && !isEmpty(this.config)) {
            this.inputs = JSON.parse(this.config);
        }
        this.customerId = this.inputs?.customerId ?? '';
        this.contractId = this.inputs?.contractId ?? '';

        if (!isEmpty(this.customerId) && this.customerId !== '0') {
            this.subscriptions.push(
                this.appFacadeService.pendo
                    .trackUserEvents(this.customerId as unknown as string)
                    .pipe(
                        tap({
                            next: () => {
                                // Success handler, do nothing if successful
                            },
                            error: error => {
                                this.logger.logError('Error occurred while tracking user events:', error, true);
                            }
                        })
                    )
                    .subscribe()
            );
        }

        this.subscriptions.push(
            this.quickLinkData$.subscribe(quickLinkData => {
                if (!quickLinkData || (!quickLinkData.Id && !quickLinkData.ContractId)) {
                    this.invalidRequest = true;
                }
                if (quickLinkData && quickLinkData.Type === 'Stock') {
                    const stockId: string = quickLinkData?.Id ? quickLinkData?.Id : '0';
                    this.appFacadeService.loadProductDetail(
                        this.customerId ?? '',
                        this.contractId ?? '',
                        stockId ?? '0',
                        stockId ?? '0',
                        ['Id', 'Name', 'SerialNumber'],
                        () => {
                            this.isDetailPageOpened = true;
                            this.contractId = quickLinkData?.ContractId ?? '';
                            this.customerId = '0'; // customerId is set to '0' to keep API /getpdf endpoint middleware happy
                        },
                        stockId
                    );
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.map((sub: Subscription) => sub.unsubscribe());
    }

    logoutUser() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
        this.logout.emit();
        this.returnQuickLinkFromLogin.emit(this.quickLinkUrl);
    }

    returnToLogin() {
        this.returnToLoginFromQuickLink.emit(this.translateService.instant('ViewPdfLogin'));
        this.returnQuickLinkFromLogin.emit(this.quickLinkUrl);
    }

    loginButtonVisibilityChange(isVisible: boolean) {
        this.isLoginButtonVisible = isVisible;
    }
}
