import { catchError, take, map, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { clearContractinStore, setLoading, setNoMoreRecord, loadContractAction, increasePageNumberContract } from '../store/actions/contract.actions';
import { getPageNumber } from '../store/selector/contracts.selectors';
import { ConsumerPortalApiService } from './consumer-portal-api.service';
import { Observable, Subscription, of } from 'rxjs';
import { Searchable } from '../models/searchable.model';
import { ContractCP, ContractDetail } from '../models/contract-model';
import isEmpty from 'lodash-es/isEmpty';
import { FilterOutput } from '../shared/models/filters.model';
import { FeatureToggleService } from './feature-toggle.service';
import { VersionToggleService } from './version-toggle';
import { ProductDetail, ProductDetailSearchable } from '../models/product-detail';
import { CloneContract } from '../models/clone-contract.model';
import { HttpErrorResponse } from '@angular/common/http';
import { setContractCurrency } from '../store/actions/currencycode.actions';

type ContractDetailSearchable = Searchable & ContractDetail;
@Injectable({
    providedIn: 'root'
})
export class ContractService {
    constructor(
        private readonly store: Store,
        private readonly consumerPortalApi: ConsumerPortalApiService,
        private readonly orgFeatureService: FeatureToggleService,
        private readonly versionToggle: VersionToggleService
    ) {}

    loadContracts({
        customerId,
        status = [],
        clearPreviousContracts,
        startDate,
        endDate,
        search
    }: {
        customerId: string;
        status?: string[] | undefined;
        clearPreviousContracts?: boolean;
        startDate?: string;
        endDate?: string;
        search?: string;
    }): Subscription {
        if (clearPreviousContracts) {
            this.store.dispatch(clearContractinStore());
        }

        return this.store.pipe(select(getPageNumber), take(1)).subscribe((pageNumber: number) => {
            this.store.dispatch(setLoading({ loading: true }));
            this.getContracts(pageNumber, customerId, status, startDate, endDate, search)
                .pipe(
                    map((items: ContractCP[]) => {
                        if (items?.length === 0) {
                            this.store.dispatch(setNoMoreRecord({ record: true }));
                            this.store.dispatch(setLoading({ loading: false }));
                        }

                        if (items?.length > 0) {
                            this.store.dispatch(setNoMoreRecord({ record: false }));
                            this.store.dispatch(loadContractAction({ contracts: items }));
                            this.store.dispatch(setContractCurrency({ currencyCode: items[0].CurrencyCode || '' }));
                            this.store.dispatch(setLoading({ loading: false }));
                            if (!clearPreviousContracts) {
                                this.store.dispatch(increasePageNumberContract());
                            }
                        }
                    }),
                    catchError(async () => {
                        this.store.dispatch(setNoMoreRecord({ record: true }));
                        this.store.dispatch(setLoading({ loading: false }));
                    })
                )
                .pipe(take(1))
                .subscribe();
        });
    }
    getContracts(pageNumber: number, customerId: string, status?: string[] | undefined, startDate?: string | undefined, endDate?: string | undefined, search?: string): Observable<ContractCP[]> {
        const columns = [
            'Id',
            'CustomerId',
            'Name',
            'Status',
            'ContractStatus',
            'StartDateTime',
            'EndDateTime',
            'PONumber',
            'AdditionalFields',
            'Addresses',
            'GrandTaxTotal',
            'GrandTotal',
            'AmountDue',
            'DepotId',
            'LineItems'
        ];
        let headers = {};
        headers = {
            /* eslint-disable @typescript-eslint/naming-convention */
            /**
             * Note camelCase : DB Model
             */
            ...headers,
            'x-columns': JSON.stringify(columns)
        };
        if (pageNumber && customerId) {
            headers = {
                ...headers,
                'x-paging': JSON.stringify({
                    page: pageNumber,
                    pageSize: 10
                })
            };
        }

        const finalFilter = [];
        if (this.versionToggle.isBasicVersion()) {
            finalFilter.push({ field: 'AmountDue', type: '>', value: 0 });
        }

        // Working on Text Search Filter (Contract text searches Contract#, PO# and location)

        if (search && !isEmpty(search)) {
            if (this.orgFeatureService.isAvailable('textSearchMultipleFilters')) {
                finalFilter.push({
                    field: '',
                    type: 'OR',
                    value: [
                        { field: 'AdditionalFields.CustomerPurchaseOrder', type: 'LIKE', value: search },
                        { field: 'AdditionalFields.CustomerJobNumber', type: 'LIKE', value: search },
                        { field: 'Id', type: 'LIKE', value: search },
                        { field: 'Name', type: 'LIKE', value: search }
                    ]
                });
            } else {
                finalFilter.push({
                    field: '',
                    type: 'OR',
                    value: [{ field: 'Name', type: 'LIKE', value: search }]
                });
            }
        }

        /* Working on Status Filters */
        if (status && status?.length > 0) {
            const statusFilters: Array<{ field: string; type: string; value: string }> = [];
            status?.map((statusItem: string) => {
                if (statusItem !== 'null' || !statusItem) {
                    statusFilters.push({ field: 'Status', type: '==', value: statusItem });
                }
            });
            if (statusFilters.length > 0) {
                finalFilter.push({
                    field: '',
                    type: 'OR',
                    value: statusFilters
                });
            }
        }

        /* Then Working on Date Filters */
        if (startDate && endDate) {
            finalFilter.push({ field: 'StartDateTime', type: '>=', value: new Date(new Date(startDate).setUTCHours(0, 0, 0, 0)) });
            finalFilter.push({ field: 'EndDateTime', type: '<=', value: new Date(new Date(endDate).setUTCHours(23, 59, 59, 999)) });
        }

        if (startDate && !endDate) {
            finalFilter.push({ field: 'StartDateTime', type: '>=', value: new Date(new Date(startDate).setUTCHours(0, 0, 0, 0)) });
        }
        if (endDate && !startDate) {
            finalFilter.push({ field: 'EndDateTime', type: '<=', value: new Date(new Date(endDate).setUTCHours(23, 59, 59, 999)) });
        }
        if (finalFilter.length > 0) {
            headers = {
                ...headers,
                'x-filter': JSON.stringify(finalFilter)
            };
        }
        const url = `customer/${customerId}/contracts`;
        return this.consumerPortalApi.get(url, { headers: headers });
    }

    getContractsDetails(customerId: string, contractId: string, columns?: string[]) {
        const headers: { [key: string]: string } = {};
        const url = `customer/${customerId}/contracts/${contractId}/detail`;
        if (columns && columns.length > 0) {
            headers['x-columns'] = JSON.stringify(columns);
            return this.consumerPortalApi.get<ContractDetailSearchable>(url, { headers: headers });
        } else {
            return this.consumerPortalApi.get<ContractDetailSearchable>(url, { headers: headers }).pipe(
                tap({
                    next: (details: ContractDetail[]) => {
                        return details.map((detail: ContractDetail) => {
                            const lineItems = detail.LineItems.filter(lineItem => lineItem.Classification === 'Rental' || lineItem.Classification === 'Sale' || lineItem.Classification === null);
                            detail.LineItems = lineItems;
                        });
                    }
                })
            );
        }
    }

    getProductDetails(customerId: string, productId: string, stockId: string, columns?: string[]) {
        const url = `product/${customerId}/${productId}/${stockId}`;
        const headers: { [key: string]: string } = {};
        if (columns && columns.length > 0) {
            headers['x-columns'] = JSON.stringify(columns);
        }
        return this.consumerPortalApi.get<ProductDetailSearchable>(url, { headers: headers }).pipe(
            tap({
                next: (productDetail: ProductDetail[]) => {
                    return productDetail;
                }
            })
        );
    }

    filter($e: FilterOutput, customerId: string) {
        const filters =
            $e.multifilters?.map((value: string) => {
                return value;
            }) || [];

        const startDate: string | undefined = !isEmpty($e.dates.startDate) ? $e.dates.startDate : undefined;

        const endDate: string | undefined = !isEmpty($e.dates.endDate) ? $e.dates.endDate : undefined;

        this.loadContracts({
            customerId,
            status: filters,
            clearPreviousContracts: true,
            startDate,
            endDate,
            search: $e.search
        });
    }

    cloneContract(cloneRequest: CloneContract) {
        const params = {
            controller: `customer/${cloneRequest.customerId}/clone-contract`,
            method: 'POST',
            body: cloneRequest
        };
        return this.consumerPortalApi.post({ ...params }).pipe(
            tap({
                next: () => {
                    return of(true);
                },
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                error: (err: HttpErrorResponse) => {
                    return of(false);
                }
            })
        );
    }
}
