/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../store/state/app.state';
import * as ConfigActions from '../store/actions/config.actions';
import * as ConfigSelectors from '../store/selector/config.selectors';
import { ConsumerPortalConfig } from '../models';
import { combineLatest, map, Observable, take } from 'rxjs';
import { setLoading } from '../store/actions/loder.actions';
import { selectIsLoading } from '../store/selector/loader.selector';
import { PendoService } from '../pendo/services/pendo.service';
import { DocumentData } from '../store/reducers/documents.reducer';
import { selectAreDocumentsExist, selectAreDocumentsExistAfterLoading, selectDocumentDataByType, selectIsDocumentExists, selectIsDocumentLoading } from '../store/selector/document.selectors';
import { PdfService } from './pdf.service';
import { emptyDocumentData, loadDocument, cancelLoadDocuments, loadLinkAndOpen, loadEsignAndOpen } from '../store/actions/document.actions';
import { VersionToggleService } from './version-toggle';
import { ContractCP, ContractDetail, GetDocumentParam, LineItems } from '../models/contract-model';
import { EventLogsRequest } from '../models/event-logs.interface';
import { saveEventLogsData } from '../store/actions/event-logs.action';
import { FeatureToggleService } from './feature-toggle.service';
import { ComponentsToLoad, SubComponentsToLoad } from '../enums/components-to-load.enum';
import { setActiveTab, setSubActiveTab } from '../store/actions/active-tab.actions';
import { selectActiveTab, selectSubActiveTab } from '../store/selector/active-tab.selectors';
import { selectContractCurrency, selectCustomerCurrency } from '../store/selector/currency-code.selectors';
import { setAuthenticated, setRedirected } from '../store/actions/auth.actions';
import { selectIsAuthenticated, selectIsRedirected } from '../store/selector/auth.selector';
import { selectLoaded } from '../store/selector/loaded.selectors';
import { setAdminLoading } from '../store/actions/loaded.actions';
import {
    setSelectedContracts,
    openContract,
    unsetSelectedContracts,
    continueMultipayment,
    closePayment,
    setPaymentOpen,
    loadContractAction,
    fetchContractDetail,
    clearContractDetailInStore,
    fetchContracts,
    resetPageNumberContract,
    resetContractsLatestToShow
} from '../store/actions/contract.actions';
import {
    getContractsTotal,
    getCreditContractsTotal,
    getPaymentOpen,
    getSelectedContracts,
    getSelectedContractsModels,
    getSelectedContractsTotal,
    getSelectedCreditContracts,
    getSelectedNonCreditContracts,
    isContractSelectable,
    isContractSelected,
    selectContractDetail,
    isMaxContractSelected,
    selectContractError,
    selectTotalPaidByContractId
} from '../store/selector/contracts.selectors';
import { selectIsPaymentProcessorAvailable } from '../store/selector/processors.selectors';
import { loadPaymentProcessorAvailability } from '../store/actions/processors.actions';
import { Processors } from '../payment/models/processors.interface';
import { Redirection } from '../types/redirection.type';
import { loadVersionRedirection, loadVersionRedirectionFailure, setVersionRedirection } from '../store/actions/versionredirection.actions';
import { selectVersionRedirection } from '../store/selector/version.selector';
import { ConsumerPortalState } from '../types/state.type';
import { selectConsumerPortalState } from '../store/selector/view-state.selectors';
import { selectIsConfigSet, selectRedirection, selectRedirectionSessionData } from '../store/selector/redirection.selectors';
import { LoginSession } from '../models/login-session.model';
import { Customer } from '../models/consumer';
import { getCustomer } from '../store/selector/customer.selectors';
import { processRoute, startRedirection } from '../store/actions/redirection.action';
import { CustomerSummaryActionTypes, loadCustomer } from '../store/actions/customer-summary.actions';
import { PaymentMinMaxBoundary } from '../payment/models/payment-data.model';
import {
    selectCrossAppEvent,
    selectDialogEnabled,
    selectErrorString,
    selectLastPaymentDetail,
    selectMultipaymentBoundaries,
    selectPaymentAppInput,
    selectPaymentSectionEnabled,
    selectTransactionLoaded
} from '../store/selector/payment.selectors';
import { createTransaction, onPaymentFinished, setDialogEnabled, setPaymentSectionEnabled, setTransactionLoaded, successVerifyPayment } from '../store/actions/payment.actions';
import { PaymentAppPayOutput, PayOutputEvent, PorPayEvent } from '../payment/models/paymentOutput';
import { selectDialogContentType, selectIsDialogDisplayed } from '../store/selector/dialog.selectors';
import { setDialogDisplayed, showDialog } from '../store/actions/dialog.actions';
import { DialogContentType } from '../enums/dialog-type.enum';
import { DEFAULT_CURRENCY, DEFAULT_GBP_CURRENCY } from '../constants/default.const';
import { PayMinimumAmount } from '../models/pay-minimum-amount.model';
import { loadProduct, loadProductSuccess, resetProduct } from '../store/actions/product.actions';
import { selectProduct } from '../store/selector/product.selectors';
import { ProductState } from '../store/reducers/product.reducer';
import { ProductDetail } from '../models/product-detail';
import { PaymentState } from '../store/state/payment.state';
import { ConfigState } from '../store/state/config.state';
import { isLineItemCalledOff } from '../store/selector/itemsout.selectors';
import { fetchItemsOut } from '../store/actions/items-out-action.actions';
import { FetchItemsOutParams } from '../models/itemsout.model';
import { FetchContractsParams } from '../models/contracts.model';
import { selectPageSizeValues } from '../store/selector/pagination.selectors';
import { changePageSizeValue } from '../store/actions/pagination.action';
import { setDeviceType } from '../store/actions/app.actions';
import { selectIsMobile } from '../store/selector/app.selectors';
import { PaymentTransactionType } from '../enums/payment-method.enum';
/**
 * This service is used to manage Consumer Portal Config to store
 */
@Injectable({
    providedIn: 'root'
})
export class AppFacadeService {
    constructor(
        private readonly store: Store<AppState>,
        private readonly paymentStore: Store<PaymentState>,
        private readonly configStore: Store<ConfigState>,
        public readonly pendo: PendoService,
        public readonly pdf: PdfService,
        public readonly versionToggleService: VersionToggleService,
        public readonly featureToggleService: FeatureToggleService
    ) {}

    setConfig(config: ConsumerPortalConfig): void {
        this.store.dispatch(ConfigActions.setConfig({ config }));
    }

    getConfig(): Observable<ConsumerPortalConfig> {
        return this.configStore.pipe(select(ConfigSelectors.selectConfig));
    }

    getConfigPayMinimumAmount(): Observable<PayMinimumAmount> {
        return this.store.pipe(select(ConfigSelectors.selectPayMinimumAmount));
    }

    setLoading(isLoading: boolean): void {
        this.store.dispatch(setLoading(isLoading));
    }

    getLoader(): Observable<boolean> {
        return this.store.pipe(select(selectIsLoading));
    }

    getPdfdocument(docType: string): Observable<DocumentData[]> {
        return this.store.pipe(select(selectDocumentDataByType(docType)));
    }

    isDocumentExists(docType: string): Observable<boolean> {
        return this.store.pipe(select(selectIsDocumentExists(docType)));
    }

    isDocumentLoading(docType: string): Observable<boolean> {
        return this.store.pipe(select(selectIsDocumentLoading(docType)));
    }

    checkIfDocumentsExistAfterLoading(): Observable<boolean> {
        return this.store.pipe(select(selectAreDocumentsExistAfterLoading));
    }

    checkIfDocumentsExist(): Observable<boolean> {
        return this.store.pipe(select(selectAreDocumentsExist));
    }

    emptyDocument() {
        this.store.dispatch(emptyDocumentData());
    }

    loadDocuments(contractdetails: GetDocumentParam) {
        this.store.dispatch(loadDocument({ contractdetails }));
    }

    openPdfdocument(docType: string): Observable<DocumentData[]> {
        return this.store.pipe(take(1), select(selectDocumentDataByType(docType)));
    }

    cancelLoadDocuments() {
        this.store.dispatch(cancelLoadDocuments());
    }

    /**
     * Dispatch action to save event logs
     * @param data
     */
    saveEventLogs(data: EventLogsRequest) {
        this.store.dispatch(saveEventLogsData({ payload: data }));
    }

    /**
     * Set active tab to state
     * @param activeTab
     */
    setActiveTab(activeTab: ComponentsToLoad): void {
        if (activeTab === ComponentsToLoad.Logout) {
            this.updateLocation(activeTab);
            return;
        }
        this.store.dispatch(setActiveTab({ activeTab }));
    }

    /**
     * Get the active tab
     * @returns Observable<ComponentsToLoad>
     */
    getActiveTab(): Observable<ComponentsToLoad> {
        return this.store.pipe(select(selectActiveTab));
    }

    /**
     * Set sub active tab to state
     * @param subActiveTab
     */
    setSubActiveTab(subActiveTab: SubComponentsToLoad): void {
        this.store.dispatch(setSubActiveTab({ subActiveTab }));
    }

    /**
     * Get the sub active tab
     * @returns Observable<ComponentsToLoad>
     */
    getSubActiveTab(): Observable<SubComponentsToLoad | null> {
        return this.store.pipe(select(selectSubActiveTab));
    }

    /**
     * Update location history on address bar
     * @param tab
     * @returns
     */
    updateLocation(tab: ComponentsToLoad | string): void {
        const currentState = history.state || window.location.href;
        if (this.versionToggleService.isConsumerQuickLinkVersion()) {
            return;
        }
        if (tab === ComponentsToLoad.Logout) {
            /**
             * Set to default view when logout performed
             */
            this.setActiveTab(ComponentsToLoad.AccountSummary);
            history.replaceState(currentState, '', `#`);
            return;
        }
        history.replaceState(currentState, '', `#${tab}`);
    }

    getCurrency(): Observable<string> {
        return combineLatest([this.store.pipe(select(selectContractCurrency)), this.store.pipe(select(selectCustomerCurrency))]).pipe(
            map(([contractCurrency, customerCurrency]) => contractCurrency || customerCurrency || this.getDefaultCurrency())
        );
    }

    setAuthenticated(isAuthenticated: boolean): void {
        this.store.dispatch(setAuthenticated(isAuthenticated));
    }

    getAuthenticated(): Observable<boolean> {
        return this.store.pipe(select(selectIsAuthenticated));
    }

    /**
     * Set payment section enabled to state
     * @param setPaymentSectionEnabled
     */
    setPaymentSectionEnabled(action: boolean): void {
        return this.paymentStore.dispatch(setPaymentSectionEnabled({ enabled: action }));
    }

    /**
     * Get payment section enabled or not
     * @returns Observable<boolean>
     */
    isPaymentSectionEnabled(): Observable<boolean> {
        return this.paymentStore.pipe(select(selectPaymentSectionEnabled));
    }

    setAdminLoaded(isLoaded: boolean): void {
        this.store.dispatch(setAdminLoading(isLoaded));
    }

    getAdminLoaded(): Observable<boolean> {
        return this.store.pipe(select(selectLoaded));
    }

    setContracts(contracts: ContractCP[]): void {
        this.store.dispatch(loadContractAction({ contracts }));
    }

    setSelectedContracts(selectedRows: string[]): void {
        this.store.dispatch(setSelectedContracts({ selectedContracts: selectedRows }));
    }

    unsetSelectedContracts(contractIds: string[]): void {
        this.store.dispatch(unsetSelectedContracts({ unselectContracts: contractIds }));
    }

    isContractAvailableInState(contractId: string): Observable<boolean> {
        return this.store.pipe(select(isContractSelected(contractId)));
    }

    getContractsTotal(): Observable<number> {
        return this.store.pipe(select(getContractsTotal));
    }

    getSelectedCreditContractsTotal(): Observable<number> {
        return this.store.pipe(select(getCreditContractsTotal));
    }

    getSelectedContractsTotal(): Observable<number> {
        return this.store.pipe(select(getSelectedContractsTotal));
    }

    getSelectedCreditContracts(): Observable<ContractCP[]> {
        return this.store.pipe(select(getSelectedCreditContracts));
    }

    getSelectedNonCreditContracts(): Observable<ContractCP[]> {
        return this.store.pipe(select(getSelectedNonCreditContracts));
    }

    /**
     * Dispatch action to get payment processors
     * @param customerId
     */
    getPaymentProcessors(customerId: string) {
        this.store.dispatch(loadPaymentProcessorAvailability({ customerId }));
    }

    /**
     * Determined Payment Processors are available to take payment or not
     * @returns Observable<boolean>
     */
    isPaymentProcessorsAvailable(): Observable<boolean | Processors[] | null> {
        return this.store.pipe(select(selectIsPaymentProcessorAvailable));
    }

    getSelectedContracts(): Observable<string[]> {
        return this.store.pipe(select(getSelectedContracts));
    }
    /**
     * determine, is maximum contract selected or not
     * @returns boolean
     */
    isMaxContractSelected(): Observable<boolean> {
        return this.store.pipe(select(isMaxContractSelected));
    }

    getSelectedContractsModels(): Observable<ContractCP[]> {
        return this.store.pipe(select(getSelectedContractsModels));
    }

    setRedirected(isRedirected: boolean) {
        this.store.dispatch(setRedirected(isRedirected));
    }

    getRedirected(): Observable<boolean> {
        return this.store.pipe(select(selectIsRedirected));
    }

    getloadVersion(orgId: string): void {
        this.store.dispatch(loadVersionRedirection({ orgId }));
    }

    setVersionRedirection(redirectionVersion: Redirection): void {
        this.store.dispatch(setVersionRedirection({ redirectionVersion }));
    }

    loadVersionRedirectionFailure(error: string): void {
        this.store.dispatch(loadVersionRedirectionFailure({ error }));
    }

    getVersionRedirection(): Observable<Redirection | null> {
        return this.store.select(selectVersionRedirection);
    }

    getStates(): Observable<ConsumerPortalState> {
        return this.store.pipe(select(selectConsumerPortalState));
    }

    getRedirection(): Observable<Redirection | null> {
        return this.store.select(selectRedirection);
    }

    getRedirectionSessionData(): Observable<LoginSession | null> {
        return this.store.select(selectRedirectionSessionData);
    }

    getIsRedirection(): Observable<boolean> {
        return this.store.select(selectIsConfigSet);
    }

    getCustomer(): Observable<Customer> {
        return this.store.pipe(select(getCustomer));
    }

    startRedirection(toLoad: ComponentsToLoad, orgId: string, cpInput: ConsumerPortalConfig): void {
        this.store.dispatch(
            startRedirection({
                redirection: { toLoad, orgId: orgId || '' },
                cpInput
            })
        );
    }

    loadCustomerById(customerId: string): void {
        const customer: Customer = { id: customerId, name: '' };
        this.store.dispatch(loadCustomer({ customer }));
    }

    loadLinkAndOpen(): void {
        this.store.dispatch(loadLinkAndOpen());
    }

    loadEsignAndOpen(): void {
        this.store.dispatch(loadEsignAndOpen());
    }

    openContract(selectedContractId: string): void {
        this.store.dispatch(openContract({ selectedContractId }));
    }

    multiPaymentContinue(): void {
        this.store.dispatch(continueMultipayment());
    }

    paymentClose(): void {
        this.store.dispatch(closePayment());
    }

    setPaymentOpen(open: boolean): void {
        this.store.dispatch(setPaymentOpen({ paymentOpen: open }));
    }

    isPaymentOpen(): Observable<boolean> {
        return this.store.pipe(select(getPaymentOpen));
    }

    getMultipaymentBoundaries(): Observable<PaymentMinMaxBoundary[] | null> {
        return this.store.pipe(select(selectMultipaymentBoundaries));
    }

    createTransaction(amount: string | null, transactionType: PaymentTransactionType | null): void {
        this.store.dispatch(createTransaction({ amount, transactionType }));
    }

    isContractSelectable(contractId: string): Observable<boolean | null> {
        return this.store.pipe(select(isContractSelectable(contractId)));
    }

    getTotalPaid(contractId: string): Observable<number | string> {
        return this.store.select(selectTotalPaidByContractId(contractId));
    }

    getTransactionLoaded(): Observable<boolean> {
        return this.store.pipe(select(selectTransactionLoaded));
    }

    getPaymentAppInput(): Observable<string | undefined> {
        return this.store.pipe(select(selectPaymentAppInput));
    }

    setTransactionLoaded(loaded: boolean): void {
        this.store.dispatch(setTransactionLoaded({ transactionLoaded: loaded }));
    }

    onPaymentFinished(paymentAppOutput: PayOutputEvent): void {
        this.store.dispatch(onPaymentFinished({ paymentAppOutput }));
    }

    isDialogEnabled(): Observable<boolean> {
        return this.store.pipe(select(selectDialogEnabled));
    }

    setDialogEnabled(enabled: boolean): void {
        this.store.dispatch(setDialogEnabled({ dialogEnabled: enabled }));
    }

    closePaymentDialog(): void {
        this.setDialogEnabled(false);
        this.paymentClose();
        this.setTransactionLoaded(false);
    }

    getPaymentError(): Observable<string | undefined> {
        return this.store.pipe(select(selectErrorString));
    }

    isContractSelectableAndAvailable(contractId: string): Observable<boolean> {
        const isFeatureAvailable = this.featureToggleService.isAvailable('multiplePaymentAbility');
        const isContractSelectable$ = this.isContractSelectable(contractId);

        return isContractSelectable$.pipe(map(isSelectable => isFeatureAvailable && isSelectable === true));
    }

    getLastPaymentDetails(): Observable<PaymentAppPayOutput | undefined> {
        return this.store.pipe(select(selectLastPaymentDetail));
    }

    isDialogDisplayed(): Observable<boolean> {
        return this.store.pipe(select(selectIsDialogDisplayed));
    }

    getDialogContentType(): Observable<DialogContentType | undefined> {
        return this.store.pipe(select(selectDialogContentType));
    }

    openDialog(dialogContentType: DialogContentType): void {
        this.store.dispatch(showDialog({ dialogContentType }));
    }

    toggleDialog(enabled: boolean): void {
        this.store.dispatch(setDialogDisplayed({ isDialogDisplayed: enabled }));
    }

    onSuccessVerifyPayment(detail: PaymentAppPayOutput): void {
        this.store.dispatch(successVerifyPayment({ lastPaymentDetail: detail }));
    }

    /**
     * Get Default Currency based on org Type
     * @returns string
     */
    getDefaultCurrency() {
        return this.featureToggleService.isSyrinx() ? DEFAULT_GBP_CURRENCY : DEFAULT_CURRENCY;
    }

    /**
     * Fetch contract details
     */
    fetchContractDetail(forPayment: boolean, customerId?: string, contractId?: string): void {
        this.store.dispatch(fetchContractDetail({ forPayment, customerId, contractId }));
    }

    /**
     * get contract details from state
     */
    getContractDetail(): Observable<ContractDetail | null> {
        return this.store.pipe(select(selectContractDetail));
    }

    /**
     * Clear contract detail on close
     */
    clearContractDetail(): void {
        this.store.dispatch(clearContractDetailInStore());
    }
    /**
     * Get error of api calls
     * @returns observable<string>
     */
    getContractDetailError(): Observable<string | null> {
        return this.store.pipe(select(selectContractError));
    }

    /**
     * Get CustomerId from config
     * @returns observable<string>
     */
    getCustomerId(): Observable<string> {
        return this.store.pipe(select(ConfigSelectors.selectCustomerId));
    }

    /**
     * Fetch Customer from Id
     * @param customerId
     */
    fetchCustomerById(customerId: string): void {
        this.store.dispatch({ type: CustomerSummaryActionTypes.LoadCustomer, payload: { id: customerId } });
    }

    processRoute(cpInput: ConsumerPortalConfig, orgId: string): void {
        this.store.dispatch(processRoute({ cpInput, orgId }));
    }

    /**
     * Get the event data from state
     * @returns Observable<PorPayEvent>
     */
    getPaymentCrossAppEvent(): Observable<PorPayEvent | null> {
        return this.store.pipe(select(selectCrossAppEvent));
    }

    loadProductDetail(customerId: string, contractId: string, productId: string, stockId: string, columns: string[], callback?: () => void, documentValue?: string): void {
        this.store.dispatch(loadProduct({ customerId, contractId, productId, stockId, columns, callback, documentValue }));
    }

    dispatchResetPageNumberContract(): void {
        this.store.dispatch(resetPageNumberContract());
    }

    dispatchResetContractsLatestToShow(): void {
        this.store.dispatch(resetContractsLatestToShow());
    }

    dispatchFetchContract(params: FetchContractsParams): void {
        const { customerId, status = [], startDate, endDate, search } = params;

        this.store.dispatch(fetchContracts({ customerId, status, startDate, endDate, search }));
    }

    getProductDetail(): Observable<ProductState> {
        return this.store.pipe(select(selectProduct));
    }

    resetProductDetail(): void {
        this.store.dispatch(resetProduct());
    }

    setProductDetail(data: ProductDetail) {
        this.store.dispatch(loadProductSuccess({ productDetail: [data] }));
    }

    /**
     * LineItem called off completely or not
     * @param id string
     * @returns boolean
     */
    isLineItemCalledOff(lineItemId: string): Observable<boolean> {
        return this.store.pipe(select(isLineItemCalledOff(lineItemId)));
    }

    dispatchFetchItemsOut(params: FetchItemsOutParams): void {
        const { customerId, startDate, endDate, search, status } = params;

        this.store.dispatch(fetchItemsOut({ customerId, startDate, endDate, search, status }));
    }

    getPageSizeValues(): Observable<{ selectedPageSize: number; previousPageSize: string | number }> {
        return this.store.pipe(select(selectPageSizeValues));
    }

    setPageSizeValue(value: string | number): void {
        this.store.dispatch(changePageSizeValue({ value }));
    }

    /**
     * Set App Render Device Type
     * @param isMobile
     */
    setAppDeviceType(isMobile: boolean): void {
        this.store.dispatch(setDeviceType({ isMobile }));
    }

    /**
     * Get App Render Device Type
     * @returns Observable
     */
    getAppDeviceType(): Observable<boolean> {
        return this.store.pipe(select(selectIsMobile));
    }
}
